.drawer {
  height: 100vh;
  width: 300px;
  position: absolute;
  background: white;
  z-index: 50;
  top: 0;
  right: 0;
  padding: 2rem;
  border-left: solid 1px #bbb;
  transition: all .3s linear !important;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
}

.header h2 {
  flex: 1;
}

.filter {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: solid 1px #bbb;
}