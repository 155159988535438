.Polaris-IndexTable__TableCell--first,
.Polaris-IndexTable__TableHeading--first,
.Polaris-IndexTable__StickyColumnHeaderCheckbox {
  display: none;
}

.Polaris-IndexTable__TableHeading--second {
  padding-left: 1.6rem;
}

.Polaris-IndexTable__TableCell {
  padding-left: 1.6rem; 
}

.Polaris-IndexTable--stickyTableHeadingSecondScrolling {
  padding: 0;
}